.navbar{
  background-color: #fff !important;
}

.navbar-brand{
  font-weight: 700;
}

@media (min-width: 992px){
  .nav-item .nav-link {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}